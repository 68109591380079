import SharedSettings from "../common/sharedSettings.json";

const SPA = {
    common: {
        AccessWorkflow: "Acceder al flujo de trabajo",
        AccessStationsGrid: "Acceder a la cuadrícula de estaciones",
        AccessStationsMap: "Acceder al mapa de estaciones",
        AccessZonesGrid: "Acceder a la cuadrícula de zonas",
        AccessZonesMap: "Acceder al mapa de zonas",
        Actions: "Acciones",
        Add: "Añadir",
        AddAnElement: "Añadir un elemento",
        AddSubEntity: "Añadir subentidad",
        Assign: "Asignar un rol",
        AssignToInternalUsers: "Asignar a usuarios internos",
        AssignToExternalUsers: "Asignar a usuarios externos",
        AlertsOnly: "Solo alertas",
        AlertsToSee: 
            "Hay algunas alertas sobre este elemento. Más información en la cuadrícula.",
        ApplicationDown: "La aplicación está actualmente en mantenimiento",
        ApplicationUnderMaintenance: "La aplicación estará en mantenimiento desde %{startDate} hasta %{endDate}",
        Back: "Atrás",
        Bookmarks: "Marcadores",
        Cancel: "Cancelar",
        CannotAccessThisPage: "No se puede acceder a esta página",
        Continue: "Continuar",
        ChangesHistory: "Historial de cambios",
        CoordinateSystem: "Sistema de coordenadas",
        CreatingNew: "Creando un nuevo",
        DefineA: "Definir un",
        Delete: "Eliminar",
        DeselectAll: "Deseleccionar todo",
        DontHaveAccessToAnyEntity: "Actualmente no tienes acceso a ninguna entidad",
        Download: "Descargar",
        Edit: "Editar",
        Editing: "Editando el",
        AddingEnglish: "Añadiendo valor en inglés para",
        EditEntityExtent: "Editar la extensión de la entidad",
        EditZone: "Editar zona",
        EditTreeVisibility: "Editar la visibilidad del árbol",
        ExcelExport: "Exportar a Excel",
        False: "Falso",
        FullName: "Nombre completo",
        GetResults: "Obtener resultados",
        GINTExport: "Exportar a gINT",
        GridView: "Vista en cuadrícula",
        Group: "Grupo",
        Help: "Ayuda",
        Hidden: "Oculto",
        Loading: "Cargando...",
        Location: "Ubicación",
        Mandatory: "Obligatorio",
        MapView: "Vista del mapa",
        Move: "Mover",
        New: "Nuevo",
        NewFeaturesAreAdded: "Se han añadido nuevas funciones",
        Next: "Siguiente",
        NoAlerts: "No hay alertas para mostrar",
        NoResults: "No hay resultados para mostrar",
        OneTsigane: "Un Tsigane",
        OpenNotifications: "Abrir notificaciones",
        Overwrite: "Sobrescribir",
        QuickSearchInto: "Búsqueda rápida en:",
        QuickSearchFilter: "Opciones de filtro de búsqueda rápida",
        ParentFieldIsRequired: "El campo principal es obligatorio.",
        PreviewFile: "Previsualizar el archivo",
        Private: "Privado",
        RedirectTo: "Redirigir a:",
        Refresh: "Actualizar",
        ResultsFor: "Resultados para %{value}",
        RemoveAccessRight: "Eliminar derechos de acceso",
        Search: "Buscar",
        SearchInTable: "Buscar en la tabla",
        SeeAlerts: "Ver alertas",
        SeeMore: "Ver más",
        Select: "Seleccionar",
        SelectAll: "Seleccionar todo",
        SettingsPanel: "Panel de configuración",
        Skip: "Saltar",
        SkipUpload: "Saltar carga",
        Close: "Cerrar",
        Remove: "Eliminar",
        Request: "Solicitar",
        Role: "Rol",
        newRef: "Solicitar una nueva referencia",
        addNewRef: "Solicitar una nueva referencia",
        Save: "Guardar",
        Translate: "Traducir",
        Tree: "Árbol",
        True: "Verdadero",
        Workflow: "Flujo de trabajo",
        GenerateAToken: "Generar un token",
        CopytoClipboard: "Copiar al portapapeles",
        CopiedSuccessfully: "Copiado con éxito",
        SavedSuccessfully: "Guardado con éxito",
        featuresmessage: "Se ha lanzado una nueva versión (versión 1.3008.1) de la aplicación. Incluye las siguientes nuevas características:",
    },
    contact: {
        FileUploadedSuccessfully: "%{fileName} cargado con éxito",
        NoFileToDownload: "¡No hay archivos para descargar!",
        YouHaveAProblem: "Tienes un problema",
        ContactForm: "Formulario de contacto",
        YouHaveAnIdea: "Tienes una idea",
        QuestionAboutTheApp: "Una pregunta sobre la aplicación",
        WantToTrackAProblem: "Quiero rastrear un problema",
        QuestionAboutUsingTheToolWithBranches: "Una pregunta sobre el uso de la herramienta con sucursales",
        BranchManagers: "Gerentes de sucursal"
    },
    diagram: {
        Draft: "Borrador",
        Failed: "Fallido",
        Final: "Final",
        Finalize: "Finalizar",
        ForValidation: "Para Validación",
        New: "Nuevo",
        NotValidated: "No Validado",
        NotValid: "No Válido",
        OK: "OK",
        Ok: "Ok",
        Processing: "Procesando",
        Reject: "Rechazar",
        Rejected: "Rechazado",
        Remove: "Eliminar",
        Removed: "Eliminado",
        Requested: "Solicitado",
        Reset: "Restablecer",
        Rework: "Rehacer",
        Reviewed: "Revisado",
        Submit: "Enviar",
        Submitted: "Enviado",
        UnderValidation: "En Validación",
        Validated: "Validado",
        Validate: "Validar",
        Valid: "Válido",
        Waiting: "Esperando",
    },
    eddDataValidation: {
        Stations: "Estaciones",
        Samplings: "Muestras",
        InSituUndergroundMeasObs: "Observaciones de mediciones subterráneas in situ",
        LaboResult: "Resultados de laboratorio",
        SpeciesIdentification: "Identificación de especies",
        EddValidationMayTakeSomeMinutes: "La carga puede tardar unos minutos dependiendo de la cantidad de datos presentes en el EDD."
    },
    enum: {
        unitCategories: {
            NONE: "Ninguno",
            CONDUCTANCE: "Conductancia",
            CONDUCTIVITY: "Conductividad",
            FLAMMABILITY: "Inflamabilidad",
            HIGHT_DEPTH: "Altura/Profundidad",
            LENGTH: "Longitud",
            MASS: "Masa",
            MASS_CONCENTRATIONS: "Concentraciones de masa",
            MASS_FRACTIONS: "Fracciones de masa",
            MICROBIAL_CELLS_CONCENTRATION: "Concentración de células microbianas",
            PARTS_PER_MASS: "Partes por: Masa",
            PARTS_PER_VOLUME: "Partes por: Volumen",
            PERCENTAGE: "Porcentaje",
            PRESSURE: "Presión",
            SPEED: "Velocidad",
            TEMPERATURE: "Temperatura",
            VOLTAGE: "Voltaje",
            VOLUME: "Volumen",
            VOLUME_FLOW_RATE: "Tasa de flujo de volumen",
            WATER_HARDNESS: "Dureza del agua",
            SPECIFIC_VOLUME: "Volumen específico",
            SOUND_INTENSITY: "Intensidad del sonido",
            RADIOACTIVITY_DESINTEGRATION_RATE: "Tasa de desintegración radiactiva",
            RADIOACTIVITY_PARTICLE_FLUX: "Flujo de partículas radiactivas"
        },
        frequencyQuery: {
            Monday: "Cada lunes",
            Friday: "Cada viernes",
            BeginningMonth: "Cada comienzo de mes",
            EndMonth: "Cada fin de mes",
        },
        rights: {
            COMPANYMANAGER: "Gerente de la empresa",
            HOLDINGMANAGER: "Gerente del holding",
            ENTITYMANAGER: "Gerente de entidad",
            BRANCHMANAGER: "Gerente de sucursal",
            CONTRIBUTOR: "Colaborador",
            READER: "Lector",
            EXTERNAL: "Externo",
            GISMANAGER: "Gerente de GIS",
        },
    },
    field: {
        AdditionalExplanations: "Explicaciones adicionales",
        AnalysisGroup: "Grupo de análisis",
        Author: "Autor",
        Basemap: "Mapa base",
        Branch: "Sucursal",
        Cas: "Número CAS",
        Category: "Categoría",
        Code: "Código",
        Color: "Color",
        Confidential: "Confidencial",
        Country: "País",
        CreatedOn: "Fecha de creación",
        CreatedByUser: "Creado por el usuario",
        CreatedByUserDescription: "Identidad del usuario que creó el elemento",
        Description: "Descripción",
        DocumentCategory: "Categoría del documento",
        EddFile: "Archivo EDD",
        EndDate: "Fecha de finalización",
        Entity: "Entidad",
        EntityCode: "Código de entidad",
        EntityName: "Nombre de la entidad",
        External: "Externo",
        ExternalProvider: "Proveedor externo",
        Explaination: "Explicación",
        Family: "Familia",
        File: "Archivo",
        Filename: "Nombre del archivo",
        Firstname: "Nombre",
        Frequency: "Frecuencia",
        GisManager: "Gestor GIS",
        GroundAltitude: "Altitud del suelo",
        InitialStudy: "Estudio inicial",
        IsPrimaryEDDFile: "Documento principal del EDD",
        Key: "Clave",
        Language: "Idioma",
        Lastname: "Apellido",
        LastUpdate: "Última actualización",
        LastUpdateDescription: "Fecha y hora de la última actualización",
        LookUpTableName: "Nombre de la tabla de búsqueda",
        MapService: "Servicio de mapas",
        MaxValue: "Valor máximo",
        MinValue: "Valor mínimo",
        Name: "Nombre",
        No: "No",
        NodeType: "Tipo de nodo",
        Note: "Nota",
        Number: "Número",
        Observation: "Observación",
        Owner: "Propietario",
        Parent: "Padre",
        Phone: "Teléfono",
        PhotoContext: "Contexto de la foto",
        PointCoordinates: "Coordenadas del punto",
        PolygonCoordinates: "Coordenadas del polígono",
        PublicationDate: "Fecha de publicación",
        Public: "Público",
        QueryName: "Nombre de la consulta",
        Reference: "Referencia",
        Region: "Región",
        RelatedZones: "Zonas relacionadas",
        RelatedStations: "Estaciones relacionadas",
        RelatedStationGroups: "Grupos de estaciones relacionados",
        RelatedStudies: "Estudios relacionados",
        RequestedAccess: "Acceso solicitado",
        RequestAccessType: "Tipo de solicitud de acceso",
        RequestDate: "Fecha de solicitud",
        RequestorIGG: "Solicitante IGG",
        RequestType: "Tipo de solicitud",
        ResultsSize: "Tamaño de los resultados",
        SamplingID: "ID de muestreo",
        SubSamplingID: "ID de sub-muestreo",
        SampleType: "Tipo de muestra",
        SecurityGroupType: "Tipo de grupo de seguridad",
        Share: "Compartir",
        StartDate: "Fecha de inicio",
        StartEndDate: "Fecha de inicio / fin",
        State: "Estado",
        Station: "Estación",
        StationAir: "Aire",
        StationBiodiversity: "Biodiversidad",
        StationCode: "Código de la estación",
        StationGroups: "Grupos de estaciones",
        StationNoise: "Ruido",
        StationSedimentWater: "Agua y sedimento",
        StationSediment: "Sedimento",
        StationWater: "Agua",
        StationSoilGroundwater: "Suelo y aguas subterráneas",
        StationType: "Tipo de estación",
        Study: "Estudio",
        StudyType: "Tipo de estudio",
        StudyYear: "Año del estudio",
        Submitter: "Remitente",
        Title: "Título",
        UpdatedByUser: "Actualizado por el usuario",
        UpdatedByUserDescription: "Identidad del último usuario que actualizó el elemento",
        User: "Usuario",
        Units: "Unidades",
        Value: "Valor",
        Valid: "Válido",
        WorkOrder: "Orden de trabajo",
        Yes: "Sí",
        Zones: "Zona",
        EmailNotif: "Recibir notificaciones por correo electrónico",
        ExplanatoryText: "Token técnico que permite el acceso a las API de One-Tsigane. Este token es estrictamente personal: Su identidad será referenciada al usarlo. Es válido por 365 días:",
    },
    grid: {
        title: {
            Administration: "Administración",
            Alerts: "Alertas",
            AllUsers: "Todos los usuarios",
            Analytes: "Analitos",
            Branches: "Sucursales",
            BranchManagers: "Gerentes de sucursal",
            CompanyManagers: "Gerentes de empresa",
            HoldingManagers: "Gerentes de holding",
            Configuration: "Configuración",
            UserPref: "Preferencias del usuario",
            Countries: "Países",
            Documents: "Documentos",
            EDDFiles: "Archivos EDD",
            Entities: "Entidades",
            EntityManagers: "Gerentes de entidad",
            EntityUsers: "Usuarios de entidad",
            ExternalUsers: "Usuarios externos",
            ExternalProviders: "Proveedores externos",
            Families: "Familias",
            Files: "Archivos",
            GISManagers: "Gerentes GIS",
            InternalUsers: "Usuarios internos",
            LimitsOf: "Límites de",
            LookupHints: "Sugerencias de búsqueda",
            NewFeaturesMessage: "Mensaje de nuevas características",
            PhotoVideo: "Fotos y videos",
            Queries: "Consultas",
            Regions: "Regiones",
            Requests: "Solicitudes para validar",
            MyRequests: "Mis solicitudes",
            ReportScheduler: "Programador de informes",
            Settings: "Configuración",
            Samplings: "Muestras",
            Stations: "Estaciones",
            VisibilityEditor: "Editor de visibilidad",
            WorkorderExternalUsers: `Gestionar permisos de usuario externo en Estudio "%{value}", Orden de trabajo "%{value2}"`,
            Zones: "Zonas",
        },
    },
    tree: {
        Add: "Añadir nodo hijo a %{value}",
        AddBookmark: "Añadir a marcadores",
        AddStudy: "Añadir un estudio a %{value}",
        AddStationGroup: "Añadir un grupo de estaciones",
        Delete: "Eliminar %{value}",
        Edit: "Editar %{value}",
        EditTranslation: "Editar traducciones de %{value}",
        Entities: "Entidades",
        EntityTreeTemplate: "Modelo de árbol de entidades",
        EntityTreeTemplateEditor: "Editor del modelo de árbol de entidades",
        Limits: "Límites",
        Lookups: "Búsquedas",
        Maps: "Mapas",
        References: "Referencias",
        RemoveFromBookmarks: "Eliminar de marcadores",
        Security: "Seguridad",
        StationGroupNodeEmpty: 
            "Actualmente no hay ningún grupo de estaciones para mostrar. Haga clic aquí para crear uno nuevo",
        StudyTemplate: "Plantilla de estudio",
        StudyTemplateEditor: "Editor de plantilla de estudio %{value}",
        Users: "Usuarios",
        Requests: "Solicitudes",
        StudyType: "Tipo de estudio",
        TextType: "Tipo de texto",
    },
    dialog: {
        Alerts: "Alertas",
        AssignTo: "Asignar %{value} a un %{value2}",
        AddNewLimits: "Añadir nuevos límites.",
        CopyZonesFromStudies: "Copiar zonas de otros estudios.",
        OverwriteFileConfirmation: 
            "Este archivo ya existe, ¿estás seguro de que deseas sobrescribir la carga en curso?",
        PromptChangesHasOccured: 
            "¿Estás seguro de que deseas abandonar esta página?\n\nHas comenzado a escribir o editar una fila en la cuadrícula de datos actual.\n\nPresiona OK para continuar o Cancelar para permanecer en la página actual y guardar tus cambios.",
        DeleteConfirmationRow: "¿Estás seguro de que deseas eliminar esta fila?",
        DeleteConfirmationNode: "¿Estás seguro de que deseas eliminar este nodo?",
        Cancelled: "Cancelado",
        SessionVerification: 
            "Tu sesión está siendo verificada. Gracias por tu paciencia.",
        UploadSkipped: "Carga omitida",
        NotificationDoneConfirmation: 
            "¿Estás seguro de que deseas validar esta notificación?",
    },
    input: {
        validation: {
            required: "Este campo es obligatorio.",
            DateRangeValidation: "La fecha de inicio debe ser antes de la fecha de finalización",
            DropFileAccepted: "Suelta los archivos",
            DropFileRejected: `Lo siento, solo se permiten ${SharedSettings.MaxFilesToUpload} archivos a la vez`,
            NumberChar: "El número máximo de caracteres en este campo es: ",
            WrongDocumentExtension: `Esta es una carpeta de documentos: los archivos aceptados no pueden ser imágenes ni videos, por lo tanto, su carga será rechazada.`,
            WrongPhotoVideoExtension: `Esta es una carpeta de fotos/videos: los archivos aceptados deben ser imágenes o videos, de lo contrario, su carga será rechazada.`,
            InvalidStartingDate: 
                "La fecha de inicio no puede ser posterior a la fecha de finalización.",
            InvalidEndingDate: 
                "La fecha de finalización no puede ser anterior a la fecha de inicio.",
            InvalidRange: "El valor no coincide con el rango",
            InvalidTime: "La hora ingresada es incorrecta. Por favor, verifica nuevamente. El rango de hora válido es de 00:00 a 23:59.",
            SelectStudy: "Por favor, selecciona primero un estudio",
        },
    },
    languages: {
        Dutch: "Neerlandés",
        English: "Inglés",
        French: "Francés",
        German: "Alemán",
        Portuguese: "Portugués",
        Spanish: "Español",
    },
    map: {
        addAPoint: "Añadir un nuevo punto",
        coordinates: "Coordenadas",
        errorSIG: "No se pudo establecer la conexión con SIG.",
        errorLayerSIG: 
            'No se pudo establecer la conexión con la capa SIG "%{layerName}".',
        entityOfInterest: "Entidad de interés",
        extentOf: "Extensión de",
        latitude: "Latitud",
        longitude: "Longitud",
        mapServerLayers: "Capas del servidor de mapas",
        point: "Punto",
        polygon: "Polígono",
        removeAPoint: "Eliminar un punto",
        redirectToStation: "Ver detalles de la estación",
        mapServerLayersTypes: {
            LAYER_PICTURE: "Imágenes",
            LAYER_PLAN: "Planos",
            LAYER_TOPO: "Topografías",
            LAYER_PIPELINES: "Tuberías",
            LAYER_MISC: "Varios",
            LAYER_CADASTRE: "Catastros",
            LAYER_ZONE: "Zonas",
        },
        selectedStations: "Estaciones seleccionadas",
        stationGroupsLayers: "Capas de grupos de estaciones",
        stationsLayers: "Capas de estaciones",
        stationsOf: "Estaciones de",
        stationsOfGroup: "Estaciones del grupo",
        zonesOf: "Zonas de",
        zonesExtents: "Extensiones de zonas",
    },
    notification: {
        noNotification: "¡No tienes notificaciones!",
        errorNotifictation: "Las notificaciones no están disponibles en este momento",
    },
    misc: {
        lambdaUser: "Usuario lambda",
        From: "de:",
    },
    panel: {
        EditEntity: "Editar entidad",
        NewEntity: "Nueva entidad",
        NewSubEntity: "Nueva subentidad",
    },
    placeholder: {
        Logout: "Cerrar sesión",
        NoEntry: "Sin entrada",
        UserState: "Estado del usuario",
        EddFileState: "Estado del archivo EDD",
    },
    select: {
        Contributor: "Colaborador",
        Reader: "Lector",
        External: "Externo",
    },
    view: {
        entityPage: {
            Code: "Código",
            Name: "Nombre",
            Country: "País",
            Branch: "Sucursal",
            Parent: "Padre",
            Contact: "Contactos",
            Documents: "Documentos",
            History: "Historial",
            Home: "Inicio",
            Location: "Ubicación",
            Operations: "Operaciones",
            Photo: "Foto",
        },
        helpPage: {
            Contact: "Contacto",
            Documents: "Documentos",
            InternalUsers: "Usuarios internos",
            ExternalUsers: "Usuarios externos",
            contact: {
                FileUploadedSuccessfully: "%{fileName} cargado con éxito",
                NoFileToDownload: "¡No hay archivo para descargar!",
                AnyTechnicalIssues: "¿Algún problema técnico?",
                PleaseClickHere: "Por favor, haga clic aquí",
                AnyFurtherFunctionalities: "¿Desea alguna funcionalidad adicional en el sistema?",
                PleaseReferToThisLink: "Por favor, consulte este enlace",
                QuestionAboutTheApp: "Una pregunta sobre la aplicación",
                WantToTrackAProblem: "¿Desea rastrear un problema?",
                ForSupportAtBranchLevel: "Para soporte a nivel de gerente de sucursal, por favor contacte con",
            },
        },
        newUser: {
            CurrentRequests: "Solicitudes actuales",
            EntityAccessRequest: "Solicitar acceso a una entidad existente",
            EntityCreateRequest: "Solicitud para crear una nueva entidad",
            Welcome: "Bienvenido",
        },
        document: {
            AddMediaMetadata: "Agregar metadatos a:",
            AddDocument: "Agregar documento",
            CancelMove: "Cancelar movimiento",
            CurrentStudy: "Estudio actual:",
            Destination: "Destino",
            DragAndDropFile: `Arrastra y suelta un archivo sobre la pantalla para cargarlo, o usa el botón 'Agregar' en la barra de herramientas de la cuadrícula de datos.`,
            Extension: "Extensión",
            FilesRejected: "Los archivos han sido rechazados.",
            HelpFileUpload: "Ayuda sobre la carga de archivos",
            MaxFilesAllowed: `Una acción de carga permite un máximo de ${SharedSettings.MaxFilesToUpload} archivos a la vez.`,
            MoveDocument: "Mover un documento",
            OriginalStudy: "Estudio original:",
            MoreInfo: "Más información",
            ListAllowedExtensions: "Lista de extensiones de archivos permitidos",
            SelectDestination: "Seleccionar destino",
            UploadedBy: "Cargado por:",
        },
        eddFiles: {
            getTemplate: "Obtener plantilla de archivo",
        },
        limit: {
            add: "Añadir un nuevo límite",
            addNewLimits: "Agregar nuevos límites",
            limitSelection: "Definición y selección de objeto",
            stepper: {
                CreateNewLimit: "Crear un nuevo límite",
                EditLimit: "Editar límite",
            },
            objectSelection: {
                object: "Objeto",
                parentObject: "Objeto padre",
            },
        },
        query: {
            ranOn: "Ejecutado en",
            ranOnThe: "ejecutado en el",
            reporting: "Informes",
            resultQuery: "Resultado de la consulta",
            runQuery: "Ejecutar la consulta",
            seeResultsOnMap: "Ver estos resultados en un mapa",
            stepper: {
                CreateNewQuery: "Crear una nueva consulta",
                EditQuery: "Editar consulta",
            },
            objectSelection: {
                objectSelection: "Selección de objeto",
                object: "Objeto",
                parentObject: "Objeto padre",
                childObject: "Objeto hijo",
                removeChild: "Eliminar hijo",
            },
            conditions: {
                conditions: "Condiciones",
            },
            outputSelection: {
                outputSelection: "Selección de salida",
                Fieldname: "Nombre del campo",
                Label: "Etiqueta",
                Description: "Descripción",
                Show: "Mostrar",
            },
        },
        request: {
            ADDUSER: "Agregar un usuario",
            NEWENTITY: "Nueva entidad",
            NEWREF: "Nueva referencia",
            OptionalObservation: "Observación opcional",
        },
        station: {
            CreateNewStation: "Crear nueva estación",
            CreateStationGroup: "Crear un grupo de estaciones",
            EditStation: "Editar estación",
            StationGroup: "Grupo de estaciones",
            StationStationGroup: "Estaciones del grupo de estaciones:",
            StationGroupLoading: "Estaciones del grupo de estaciones: cargando...",
        },
        study: {
            Study: "el estudio",
            StudyWorkOrder: "Estudio %{value} : Órdenes de trabajo",
            NoStudyWorkOrder: "Estudio ... : Órdenes de trabajo",
        },
        workOrder: {
            EDDFiles: "Archivos EDD",
            ExternalUsersPermissions: "Permisos de usuarios externos",
        },
        zone: {
            CopyZones: "Copiar zonas",
            CopyZonesFromStudy: "Copiar zonas de otro estudio",
            CreateNewZone: "Crear nueva zona para",
            EditExtent: "Editar la extensión de",
            EditZone: "Editar zona",
            ExtendDefined: "Extensión definida",
            ExtendNotDefined: "Extensión no definida",
            LoadingEntity: "Cargando entidad ...",
            PreExistingZones: "Zonas preexistentes de",
        },
    },
    packages: {
        colorPicker: {
            language: "SPA",
            color: "color",
            rgb: "rgb",
            R: "R",
            G: "G",
            B: "B",
            hsv: "hsv",
            H: "H",
            S: "S",
            V: "V",
            hsl: "hsl",
            WrongFormat: "Formato incorrecto.",
            NotHexFormat: "No es un valor hexadecimal.",
            transparent: "Transparente",
            none: "Inválido",
        },
        reactAwesomeQueryBuilder: {
            and: "y",
            or: "o",
            valueLabel: "Valor",
            valuePlaceholder: "Valor",
            fieldLabel: "Campo",
            operatorLabel: "Operador",
            funcLabel: "Función",
            fieldPlaceholder: "Seleccionar campo",
            funcPlaceholder: "Seleccionar función",
            operatorPlaceholder: "Seleccionar operador",
            lockLabel: "Bloquear",
            lockedLabel: "Bloqueado",
            deleteLabel: "",
            delGroupLabel: "",
            addGroupLabel: "Agregar grupo",
            addRuleLabel: "Agregar regla",
            addSubRuleLabel: "Agregar subregla",
            notLabel: "No",
            valueSourcesPopupTitle: "Seleccionar fuente de valor",
            removeRuleConfirmOptions: {
                title: "¿Estás seguro de eliminar esta regla?",
                okText: "Sí",
                okType: "peligro",
                cancelText: "Cancelar",
            },
            removeGroupConfirmOptions: {
                title: "¿Estás seguro de eliminar este grupo?",
                okText: "Sí",
                okType: "peligro",
                cancelText: "Cancelar",
            },
            like: "como",
            not_like: "no como",
            starts_with: "empieza con",
            ends_with: "termina con",
            between: "entre",
            not_between: "no entre",
            is_null: "es nulo",
            is_not_null: "no es nulo",
            is_empty: "está vacío",
            is_not_empty: "no está vacío",
            select_any_in: "cualquiera en",
            select_not_any_in: "no en",
            proximity: "proximidad",
            enterValue: "Ingresar valor",
            enterNumber: "Ingresar número",
            enterNumberFrom: "Ingresar número desde",
            enterNumberTo: "Ingresar número hasta",
            enterDate: "Ingresar fecha",
            enterInteger: "Ingresar entero",
            enterText: "Ingresar texto",
            selectValue: "Seleccionar valor",
            selectValues: "Seleccionar valores",
        },
    },
    references: {
        Ref_AirType: "Tipo de aire",
        Ref_AnalysisGroup: "Grupo de análisis",
        Ref_AquiferStatus: "Estado del acuífero",
        Ref_BiodiversityType: "Tipo de biodiversidad",
        Ref_BoreholeStatus: "Estado del pozo",
        Ref_BoreholeType: "Tipo de pozo",
        Ref_CapType: "Tipo de tapa",
        Ref_ConstructionType: "Tipo de construcción",
        Ref_Context: "Contexto",
        Ref_DayNight: "Día y noche",
        Ref_DrillingFluid: "Fluido de perforación",
        Ref_DrillingMethod: "Método de perforación",
        Ref_EcologicalGroup: "Grupo ecológico",
        Ref_EcologicalIntegrity: "Integridad ecológica",
        Ref_EmissionSource: "Fuente de emisión",
        Ref_EquipmentType: "Tipo de equipo",
        Ref_FaunaUse: "Uso de fauna",
        Ref_GeometryType: "Tipo de geometría",
        Ref_HabitatType: "Tipo de hábitat",
        Ref_HumanUse: "Uso humano",
        Ref_Installation: "Instalación",
        Ref_IUCNStatus: "Estado de la UICN",
        Ref_LithologyType: "Tipo de litología",
        Ref_Matrix: "Matriz",
        Ref_ObsMeasure: "Medida de observación",
        Ref_ObservationMode: "Modo de observación",
        Ref_ObservationType: "Tipo de observación",
        Ref_PhotoContext: "Contexto fotográfico",
        Ref_Parameter: "Parámetro",
        Ref_PiezometerGroup: "Grupo de piezómetros",
        Ref_PresenceProof: "Prueba de presencia",
        Ref_QAQCType: "Tipo de QAQC",
        Ref_ReadingType: "Tipo de lectura",
        Ref_RecordPeriod: "Período de registro",
        Ref_RelativePosition: "Posición relativa",
        Ref_ReleaseLocation: "Ubicación de liberación",
        Ref_SampleCollectionType: "Tipo de colección de muestras",
        Ref_SampleType: "Tipo de muestra",
        Ref_SensorType: "Tipo de sensor",
        Ref_StationBiodiversityType: "Biodiversidad de estación",
        Ref_StationSedimentWaterType: "Tipo de agua y sedimento de estación",
        Ref_StratigraphicClassification: "Clasificación estratigráfica",
        Ref_TrophicGroup: "Grupo trófico",
        Ref_Unit: "Unidades",
        Ref_Vulnerability: "Vulnerabilidad",
        Ref_WaterIntermittence: "Intermitencia del agua",
        Ref_WaterLocation: "Ubicación del agua",
        Ref_WaterSource: "Fuente de agua",
        Ref_WaterType: "Tipo de agua",
        Ref_WeightBasis: "Base de peso",
    },
};
export default SPA;